import { CoreoUser } from '../../types';
import { TypeKeys } from '../actions';

export interface AuthState {
  token: string;
  user: CoreoUser;
  isAuth: boolean;
  authInProgress: boolean;
  authError: string;
  logoutInProgress: boolean;
  logoutError: string;
};

const initialState: AuthState = {
  token: null,
  user: null,
  isAuth: false,
  authInProgress: false,
  authError: null,
  logoutInProgress: false,
  logoutError: null
};

const authReducer = (state = initialState, action): AuthState => {
  switch (action.type) {
    case TypeKeys.AUTH_START: {
      return {
        ...state,
        authInProgress: true,
        authError: null
      };
    }

    case TypeKeys.AUTH_SUCCESS: {
      const { token, user } = action;
      return {
        ...state,
        isAuth: true,
        authInProgress: false,
        token,
        user
      };
    }

    case TypeKeys.AUTH_FAIL: {
      const { error } = action;
      return {
        ...initialState,
        authError: error
      };
    }

    case TypeKeys.AUTH_UPDATE_USER_SUCCESS: {
      const { user } = action;

      return {
        ...state,
        user
      }
    }

    case TypeKeys.AUTH_LOGOUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export default authReducer;
